import React, { useState } from 'react';
import axios from 'axios';
import './App.css';

function App() {
  const [platform, setPlatform] = useState("TikTok");
  const [description, setDescription] = useState("");
  const [wordCount, setWordCount] = useState(20);
  const [tone, setTone] = useState("Casual");
  const [includeHashtags, setIncludeHashtags] = useState(false);
  const [includeEmoji, setIncludeEmoji] = useState(false);
  const [captions, setCaptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const platforms = ["TikTok", "YouTube", "Instagram", "X", "Facebook", "LinkedIn"];

  const generateCaptions = async () => {
    setLoading(true);
    try {
      const response = await axios.post('https://instacomedy.com/api/generate-captions', {
        description,
        platform,
        wordCount,
        tone,
        includeHashtags,
        includeEmoji,
      });

      // Update captions state with the response or handle errors
      setCaptions(response.data.captions || ["Error generating captions"]);
    } catch (error) {
      console.error("Error generating caption", error);
      setCaptions(["Error generating captions"]);
    }
    setLoading(false);
  };

  return (
    <div className="app">
      <h1>{platform} Caption Generator</h1>
      <p>Sizzle your {platform} with unique captions – Get started today!</p>

      <div className="platform-selector">
        {platforms.map((plat) => (
          <button
            key={plat}
            className={platform === plat ? "selected" : ""}
            onClick={() => setPlatform(plat)}
          >
            {plat}
          </button>
        ))}
      </div>

      <textarea
        className="description-input"
        placeholder="Enter your description here..."
        value={description}
        onChange={(e) => setDescription(e.target.value)}
      ></textarea>

      <div className="options">
        <div className="option-item">
          <label>Maximum Number of Words:</label>
          <input
            type="number"
            min="1"
            value={wordCount}
            onChange={(e) => setWordCount(e.target.value)}
          />
        </div>

        <div className="option-item">
          <label>Tone:</label>
          <select value={tone} onChange={(e) => setTone(e.target.value)}>
            <option value="Casual">Casual</option>
            <option value="Professional">Professional</option>
            <option value="Humorous">Humorous</option>
            <option value="Inspirational">Inspirational</option>
          </select>
        </div>
      </div>

      <div className="checkboxes">
        <label>
          <input
            type="checkbox"
            checked={includeHashtags}
            onChange={() => setIncludeHashtags(!includeHashtags)}
          />
          Include Hashtags
        </label>
        <label>
          <input
            type="checkbox"
            checked={includeEmoji}
            onChange={() => setIncludeEmoji(!includeEmoji)}
          />
          Include Emoji
        </label>
      </div>

      <button className="generate-button" onClick={generateCaptions} disabled={loading}>
        {loading ? "Generating..." : "Generate Captions"}
      </button>

      <div className="captions">
        {captions.length > 0 ? (
          captions.map((caption, index) => (
            <div key={index} className="caption-box">
              <p>{caption}</p>
              <button onClick={() => navigator.clipboard.writeText(caption)}>Copy</button>
            </div>
          ))
        ) : (
          <p>No captions generated yet.</p>
        )}
      </div>
    </div>
  );
}

export default App;
